body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}


@font-face {
    font-family: 'Raleway';
    src: local('Raleway'), url(./fonts/Raleway-Regular.ttf) format('truetype');
}

.page-header-break {
    min-height: 10px;
    margin-bottom: 50px;
    background: #343841;
    -webkit-box-shadow: inset 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 5px 10px rgba(0, 0, 0, 0.2);
}

.navbar-light .navbar-nav .nav-link.anawim-link:hover {
    color: #343841;
    background-color: #fff;
    text-decoration: none;
    border-bottom: 2px solid #fac70e;
}

.navbar.navbar-expand-md.navbar-light {
    padding: 0px;
}

.map-ui {
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
}

.navbar-light .navbar-nav .nav-link.anawim-link {
    text-transform: uppercase;
    font-family: "proxima-nova", "Open Sans", sans-serif;
    font-size: 11px;
    font-weight: bold;
    padding-bottom: 2px;
    padding-top: 0.2rem;
    line-height: 20px;
    letter-spacing: 1px;
    color: #434750;
}

.navbar-light .navbar-nav .nav-link.anawim-linkend {
    text-transform: uppercase;
    font-family: "proxima-nova", "Open Sans", sans-serif;
    font-size: 11px;
    font-weight: bold;
    padding-bottom: 2px;
    padding-right: 0px;
    padding-top: 0.2rem;
    line-height: 20px;
    letter-spacing: 1px;
    color: #434750;
}

.top-bar {
    margin-top: 30px;
}

.navbar-light .navbar-brand {
    padding: 0px;
}

.sub-nav-container.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 0px;
    padding-right: 0px;
    max-width: 1170px;
}

.nav-container.container {
    max-width: 1170px;
}

@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400italic,700);

.sprite-openlock {
    background-position: -156px 0;
    width: 26px;
    height: 26px;
    margin-top: -10px;
}

span.separator {
    display: inline-block;
    font-size: 11px;
    line-height: 20px;
    color: #434750;
    display: inline-block;
    margin-top: auto;
    margin-bottom: auto;
}

@media (max-width: 1000px) {                  
    span.separator {
       display: none;
    }
 }

[class^="sprite-"] {
    display: inline-block;
    zoom: 1;
    *display: inline;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-image: url(https://www.anawimhousing.org/assets/icon-img-sprite.png);
    font: 0/0 a;
    text-indent: -999em;
}

@font-face {
    font-family: proxima-nova;
    src: url(https://use.typekit.net/af/399a91/0000000000000000000158cf/23/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("woff2"),
        url(https://use.typekit.net/af/399a91/0000000000000000000158cf/23/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("woff"),
        url(https://use.typekit.net/af/399a91/0000000000000000000158cf/23/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("opentype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: proxima-nova;
    src: url(https://use.typekit.net/af/477d8d/0000000000000000000158d7/23/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("woff2"),
        url(https://use.typekit.net/af/477d8d/0000000000000000000158d7/23/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("woff"),
        url(https://use.typekit.net/af/477d8d/0000000000000000000158d7/23/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("opentype");
    font-weight: 400;
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');