.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    /*background-color: #343841;*/
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* Header Start */
.navbar-brand {
    margin-bottom: 32px;
}

/* Header End */

/* Map Start */
.map-ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333;
  }
  
  .map-li {
    float: left;
  }

  .col-left-align {
    text-align: left;
  }
  
  .map-li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  /* Change the link color to #111 (black) on hover */
  .map-li a:hover {
    background-color: #111;
  }
  
.img-location {
    width: 100%;
    height: auto;
}

.gm-style .gm-style-iw {
    font-weight: 400;
    font-size: 14px;
    font-family: poppins;
    line-height: 1.6em;
    overflow: hidden;
}

.loc-name {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 800;
    padding-top: 10px;
    color: #515059;
    text-transform: uppercase;
}
.loc-field{
  padding-top:10px;
}
.gm-style-iw .gm-style-iw-c {
    max-width: 500px;
}

.loc-label {
    font-weight: bold;
}

.image-list-ul {
    list-style-type:none;
    white-space:nowrap;
    overflow-x:auto;
}

.image-list-li {
    display:inline;
    padding: 0px 5px;
}

/* Map End */

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    /*background-color: #343841;*/
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}