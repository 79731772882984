.card.anawim {
    border-color: #55565A;
}

.card.anawim .card-header {
    background-color: #55565A;
    color: #FFFFFF;
    font-family: "proxima-nova";
}

.card.border-primary {
    border-width: 3px;
    border-color: #f5C400 !important;
}

.statistic {
    font-size: 5rem;
}