@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400italic,700);
@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}


@font-face {
    font-family: 'Raleway';
    src: local('Raleway'), url(/static/media/Raleway-Regular.580d0778.ttf) format('truetype');
}

.page-header-break {
    min-height: 10px;
    margin-bottom: 50px;
    background: #343841;
    box-shadow: inset 0 5px 10px rgba(0, 0, 0, 0.2);
}

.navbar-light .navbar-nav .nav-link.anawim-link:hover {
    color: #343841;
    background-color: #fff;
    text-decoration: none;
    border-bottom: 2px solid #fac70e;
}

.navbar.navbar-expand-md.navbar-light {
    padding: 0px;
}

.map-ui {
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
}

.navbar-light .navbar-nav .nav-link.anawim-link {
    text-transform: uppercase;
    font-family: "proxima-nova", "Open Sans", sans-serif;
    font-size: 11px;
    font-weight: bold;
    padding-bottom: 2px;
    padding-top: 0.2rem;
    line-height: 20px;
    letter-spacing: 1px;
    color: #434750;
}

.navbar-light .navbar-nav .nav-link.anawim-linkend {
    text-transform: uppercase;
    font-family: "proxima-nova", "Open Sans", sans-serif;
    font-size: 11px;
    font-weight: bold;
    padding-bottom: 2px;
    padding-right: 0px;
    padding-top: 0.2rem;
    line-height: 20px;
    letter-spacing: 1px;
    color: #434750;
}

.top-bar {
    margin-top: 30px;
}

.navbar-light .navbar-brand {
    padding: 0px;
}

.sub-nav-container.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 0px;
    padding-right: 0px;
    max-width: 1170px;
}

.nav-container.container {
    max-width: 1170px;
}

.sprite-openlock {
    background-position: -156px 0;
    width: 26px;
    height: 26px;
    margin-top: -10px;
}

span.separator {
    display: inline-block;
    font-size: 11px;
    line-height: 20px;
    color: #434750;
    display: inline-block;
    margin-top: auto;
    margin-bottom: auto;
}

@media (max-width: 1000px) {                  
    span.separator {
       display: none;
    }
 }

[class^="sprite-"] {
    display: inline-block;
    zoom: 1;
    *display: inline;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-image: url(https://www.anawimhousing.org/assets/icon-img-sprite.png);
    font: 0/0 a;
    text-indent: -999em;
}

@font-face {
    font-family: proxima-nova;
    src: url(https://use.typekit.net/af/399a91/0000000000000000000158cf/23/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("woff2"),
        url(https://use.typekit.net/af/399a91/0000000000000000000158cf/23/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("woff"),
        url(https://use.typekit.net/af/399a91/0000000000000000000158cf/23/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("opentype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: proxima-nova;
    src: url(https://use.typekit.net/af/477d8d/0000000000000000000158d7/23/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("woff2"),
        url(https://use.typekit.net/af/477d8d/0000000000000000000158d7/23/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("woff"),
        url(https://use.typekit.net/af/477d8d/0000000000000000000158d7/23/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("opentype");
    font-weight: 400;
    font-style: normal;
}
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    /*background-color: #343841;*/
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}


@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}


@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

/* Header Start */
.navbar-brand {
    margin-bottom: 32px;
}

/* Header End */

/* Map Start */
.map-ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333;
  }
  
  .map-li {
    float: left;
  }

  .col-left-align {
    text-align: left;
  }
  
  .map-li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  /* Change the link color to #111 (black) on hover */
  .map-li a:hover {
    background-color: #111;
  }
  
.img-location {
    width: 100%;
    height: auto;
}

.gm-style .gm-style-iw {
    font-weight: 400;
    font-size: 14px;
    font-family: poppins;
    line-height: 1.6em;
    overflow: hidden;
}

.loc-name {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 800;
    padding-top: 10px;
    color: #515059;
    text-transform: uppercase;
}
.loc-field{
  padding-top:10px;
}
.gm-style-iw .gm-style-iw-c {
    max-width: 500px;
}

.loc-label {
    font-weight: bold;
}

.image-list-ul {
    list-style-type:none;
    white-space:nowrap;
    overflow-x:auto;
}

.image-list-li {
    display:inline;
    padding: 0px 5px;
}

/* Map End */

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    /*background-color: #343841;*/
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}


@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.card.anawim {
    border-color: #55565A;
}

.card.anawim .card-header {
    background-color: #55565A;
    color: #FFFFFF;
    font-family: "proxima-nova";
}

.card.border-primary {
    border-width: 3px;
    border-color: #f5C400 !important;
}

.statistic {
    font-size: 5rem;
}
